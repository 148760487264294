import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import Image from "../assets/transcycle.png";

const Login = () => {
  const [username, setUsername] = useState("");
  const [pwd, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const navigate = useNavigate();

  const signIn = (e) => {
    e.preventDefault();

    if (!username || !pwd) {
      setAuthError("Please enter a valid username and password.");
      return;
    }

    const data = { username, pwd };
    axios
      .post("https://transcycleapi.retailgate.tech:20304/auth/token", data)
      .then((response) => {
        localStorage.setItem("Token", response.data.token);
        console.log(response.data);
        navigate("/scanner");
      })
      .catch((error) => {
        setAuthError(error.response?.data?.message || "User not found");
      });
  };
  // if (localStorage.getItem("Token")) {
  //   navigate("/scanner");
  // }

  return (
    <div className="sign-in-inner">
      <form onSubmit={signIn} className="sign-in-form">
        <Container className="sign-in" fluid>
          <Row className="row-header">
            <Col lg={7} md={7} sm={7} className="column-with-image">
              <img src={Image} alt="Example Image" />
            </Col>
            <Col lg={5} md={5} sm={5} className="column-with-label">
              <h3>SIGN-IN</h3>
            </Col>
          </Row>
          {authError && (
            <Alert style={{ textAlign: "center" }} variant="danger">
              {authError}
            </Alert>
          )}
          <Row>
            <label>Username: </label>
            <Col lg={12} md={12} sm={12}>
              <input
                type="text"
                className="form-control"
                placeholder="Enter email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <label>Password</label>
            <Col lg={12} md={12} sm={12}>
              <input
                type="password"
                className="form-control"
                placeholder="Enter password"
                value={pwd}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <button type="submit" className="mx-auto btn sign-in-submit-btn">
                Sign in
              </button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default Login;
