import axios from "axios";

export async function fetchBranches() {
  // const myValue = localStorage.getItem("Token");
  try {
    const response = await axios.get(
      "https://transcycleapi.retailgate.tech:20304/registration/branches"
      // {
      //   headers: {
      //     Authorization: "Bearer " + myValue,
      //   },
      // }
    );
    // console.log(response);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch branches");
  }
}
