import React, { useState, useEffect } from "react";
import { fetchTermsAndConditions } from "../services/TermsAndConditionAPI";
import {
  Document,
  Page,
  Text,
  Link,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { Logo, Arawmoto } from "../assets/index";

// Create styles
const styles = StyleSheet.create({
  container: {
    margin: 40,
    gap: 3,
    textAlign: "left",
    color: "#7a7c7d",
    fontSize: "13px",
  },

  terms: {
    color: "black",
    textAlign: "center",
    lineHeight: "1.2px",
    fontSize: "16px",
    marginTop: 40,
  },
});

const PDFTermsandCondition = () => {
  const [termandcondition, setTermandcondition] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTermsAndConditions();
      setTermandcondition(data);
    };

    fetchData();
  }, []);

  const filteredData = termandcondition.map((item, index) => {
    const regex =
      /((http|https|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/gi;
    const contentWithLinks = item.content.replace(
      regex,
      (url) => `<a href=${url}>${url}</a>`
    );

    return (
      <View key={index}>
        <Text>
          <Text>
            {index + 1} {contentWithLinks}
          </Text>
        </Text>
        {item.subitems && (
          <View style={{ marginLeft: 20 }}>
            {item.subitems.map((subitem, subindex) => (
              <Text key={subindex}>{subitem}</Text>
            ))}
          </View>
        )}
      </View>
    );
  });
  return (
    <>
      {/* <View style={styles.terms}>
        <Text style={{ fontSize: "16px", color: "#7a7c7d" }}>
          TERMS AND CONDITIONSasdasdasd
        </Text>
      </View> */}
      <View style={styles.container}>{filteredData}</View>
      {/* {filtered} */}
      <Image src={Arawmoto} style={{ height: 100, resizeMode: "contain" }} />
    </>
  );
};

export default PDFTermsandCondition;
