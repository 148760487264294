import { Navigate } from "react-router-dom";

const getToken = () => {
  const token = localStorage.getItem("Token");

  return token;
};

const ProtectedRoutes = (props) => {
  const user = getToken();
  console.log(user);
  if (!user) {
    return <Navigate to="/login" />;
  }
  return <>{props.children}</>;
};

export default ProtectedRoutes;
