import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Scanner from "./pages/Scanner";
import TermsConditions from "./components/TermsConditions";
import ProtectedRoutes from "./services/ProtectedRoutes";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route exact path="/" element={<Register />} />
        <Route exact path="/TermsConditions" element={<TermsConditions />} />
        <Route
          path="/Scanner"
          element={
            <ProtectedRoutes>
              <Scanner />
            </ProtectedRoutes>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
