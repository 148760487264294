import axios from "axios";

export const fetchTermsAndConditions = async () => {
  try {
    const response = await axios.get(
      "https://transcycleapi.retailgate.tech:20304/registration/tnc"
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
