import React, { useState, useEffect } from "react";
import { Modal, Button, Alert, Row, Col, Container } from "react-bootstrap";
import { fetchBranches } from "../components/Branch";
import useInput from "../hooks/useInput";
import Model from "../components/Model";
import AddsCustomer from "../services/Add";
import TermsConditions from "../components/TermsConditions";
import PrivacyPolicy from "../components/PrivacyPolicies";
import { Images, Logo, Arawmoto } from "../assets/index";
import { QRCode, useQRCode } from "react-qrcode";
import { fetchTermsAndConditions } from "../services/TermsAndConditionAPI";
import {
  pdf,
  Document,
  Page,
  Font,
  Image,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import "../index.css";
import MyDocument from "../components/PDFTermsandCondition";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.includes("@");
const isNumber = (value) => value.length === 10;
const isZipCode = (value) => value.length === 4;

const Register = () => {
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [data, setData] = useState({
    promo: "",
    voucher: "",
    qrcode: "",
  });
  const dataUrl = useQRCode(data.qrcode);

  async function fetchAndSetBranches() {
    const branch_data = await fetchBranches();
    setRegions(branch_data.regions);
    setCities(branch_data.cities);
    setBranches(branch_data.branches);
    console.log(branch_data);
  }

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(isNotEmpty);

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(isNotEmpty);
  const {
    value: middleNameValue,
    isValid: middleNameIsValid,
    hasError: middleNameHasError,
    valueChangeHandler: middleNameChangeHandler,
    inputBlurHandler: middleNameBlurHandler,
  } = useInput(isNotEmpty);
  const {
    value: mobileNumberValue,
    isValid: mobileNumberIsValid,
    hasError: mobileNumberHasError,
    valueChangeHandler: mobileNumberChangeHandler,
    inputBlurHandler: mobileNumberBlurHandler,
  } = useInput(isNumber, "number");
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail, "email");

  const {
    value: houseUnitValue,
    isValid: houseUnitIsValid,
    hasError: houseUnitHasError,
    valueChangeHandler: houseUnitChangeHandler,
    inputBlurHandler: houseUnitBlurHandler,
  } = useInput(isNotEmpty, "alphanumeric");
  const {
    value: streetValue,
    isValid: streetIsValid,
    hasError: streetHasError,
    valueChangeHandler: streetChangeHandler,
    inputBlurHandler: streetBlurHandler,
  } = useInput(isNotEmpty, "alphanumeric");
  const {
    value: barangayValue,
    isValid: barangayIsValid,
    hasError: barangayHasError,
    valueChangeHandler: barangayChangeHandler,
    inputBlurHandler: barangayBlurHandler,
  } = useInput(isNotEmpty, "alphanumeric");
  const {
    value: cityValue,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInput(isNotEmpty);
  const {
    value: regionValue,
    isValid: regionIsValid,
    hasError: regionHasError,
    valueChangeHandler: regionChangeHandler,
    inputBlurHandler: regionBlurHandler,
  } = useInput(isNotEmpty, "alphanumeric");
  const {
    value: zipCodeValue,
    isValid: zipCodeIsValid,
    hasError: zipCodeHasError,
    valueChangeHandler: zipCodeChangeHandler,
    inputBlurHandler: zipCodeBlurHandler,
  } = useInput(isZipCode, "number");
  const {
    value: modelValue,
    isValid: modelIsValid,
    hasError: modelHasError,
    valueChangeHandler: modelChangeHandler,
    inputBlurHandler: modelBlurHandler,
  } = useInput(isNotEmpty, "select");

  const {
    value: bRegionValue,
    isValid: bRregionIsValid,
    hasError: bRegionHasError,
    valueChangeHandler: bRegionChangeHandler,
    inputBlurHandler: bRegionBlurHandler,
  } = useInput(isNotEmpty, "select");

  const {
    value: bCityValue,
    isValid: bCityIsValid,
    hasError: bCityHasError,
    valueChangeHandler: bCityChangeHandler,
    inputBlurHandler: bCityBlurHandler,
    reset: resetBranchCity,
  } = useInput(isNotEmpty, "select");

  const {
    value: branchValue,
    isValid: branchIsValid,
    hasError: branchHasError,
    valueChangeHandler: branchChangeHandler,
    inputBlurHandler: branchBlurHandler,
    reset: resetBranch,
  } = useInput(isNotEmpty, "select");

  let formIsValid = false;

  if (
    lastNameIsValid &&
    firstNameIsValid &&
    middleNameIsValid &&
    mobileNumberIsValid &&
    emailIsValid &&
    houseUnitIsValid &&
    streetIsValid &&
    barangayIsValid &&
    cityIsValid &&
    regionIsValid &&
    zipCodeIsValid &&
    modelIsValid &&
    bRregionIsValid &&
    bCityIsValid &&
    branchIsValid
  ) {
    formIsValid = true;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    lastNameBlurHandler();
    firstNameBlurHandler();
    middleNameBlurHandler();
    mobileNumberBlurHandler();
    emailBlurHandler();
    houseUnitBlurHandler();
    streetBlurHandler();
    barangayBlurHandler();
    cityBlurHandler();
    regionBlurHandler();
    zipCodeBlurHandler();
    modelBlurHandler();
    bRegionBlurHandler();
    bCityBlurHandler();
    branchBlurHandler();

    if (!formIsValid) {
      return;
    }
    console.log("SUBMITTING");
    const newCustomer = {
      lastName: lastNameValue,
      firstName: firstNameValue,
      middleName: middleNameValue,
      email: emailValue,
      model: modelValue,
      number: mobileNumberValue,
      branchregion: bRegionValue,
      branchcity: bCityValue,
      branchlocation: branchValue,
      houseNumber: houseUnitValue,
      street: streetValue,
      barangay: barangayValue,
      city: cityValue,
      region: regionValue,
      zipCode: zipCodeValue,
    };

    AddsCustomer.addCustomer(newCustomer)
      .then((res) => {
        setShowModal(true);
        // setMessage({ error: false, msg: "Register Successfully!" });
        setData({
          promo: res.data.promo,
          voucher: res.data.voucher_code,
          qrcode: res.data.qrstring,
        });
        console.log(res.data);
      })
      .catch((error) => {
        const errorMessage = error.response.data.errorMessage;
        setMessage({ error: true, msg: errorMessage });
        resetEmail();
        console.error(errorMessage);
      });
  };
  const closeModalHandler = () => {
    setShowModal(false);
    window.location.reload();
  };
  useEffect(() => {
    resetBranchCity();
    resetBranch();
    fetchAndSetBranches();
  }, [bRegionValue]);

  const styles = StyleSheet.create({
    name: {
      color: "black",
      textAlign: "left",
      marginLeft: 24,
      marginTop: 10,
      lineHeight: "1.3px",
    },
    title: {
      // margin: 20,
      fontSize: 25,
      textAlign: "center",
      fontFamily: "Oswald",
    },

    body: {
      flexGrow: 0,
    },
    row: {
      flexGrow: 1,
      flexDirection: "row",
    },
    info: {
      color: "black",
      textAlign: "left",
      marginLeft: 24,
      marginTop: 24,
      lineHeight: "1.3px",
      fontSize: "14px",
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: 370,
      width: "80%",
      textTransform: "uppercase",
    },
    voucher: {
      padding: 5,
      marginRight: 24,
      marginLeft: 10,
      width: "30%",
      flexShrink: 50,
      flexGrow: 0.5,
      alignItems: "right",
    },
    block: {
      flexGrow: 1,
    },
    container: {
      margin: 20,
      gap: 3,
      textAlign: "left",
      color: "#7a7c7d",
      fontSize: "13px",
    },

    terms: {
      color: "black",
      textAlign: "center",
      lineHeight: "1.2px",
      fontSize: "16px",
      marginTop: 40,
    },
  });
  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });
  const [termandcondition, setTermandcondition] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTermsAndConditions();
      setTermandcondition(data);
    };

    fetchData();
  }, []);

  const termsandconditionData = termandcondition.map((item, index) => {
    const regex =
      /((http|https|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/gi;
    const contentWithLinks = item.content.replace(regex, (url) => `${url}`);

    return (
      <View key={index}>
        <Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: `${index + 1} ${contentWithLinks}`,
            }}
          />
          {index + 1} {contentWithLinks}
          {/* </Text> */}
        </Text>
        {item.subitems && (
          <View style={{ marginLeft: 20 }}>
            {item.subitems.map((subitem, subindex) => (
              <Text key={subindex} style={{ textDecoration: "none" }}>
                {"\u2022"} {subitem}
              </Text>
            ))}
          </View>
        )}
      </View>
    );
  });
  console.log(termsandconditionData);
  // const [link, setLink] = useState(true);
  const handleGeneratePdf = () => {
    setGeneratingPdf(true);
    pdf(
      <Document>
        <Page size="LEGAL">
          <Image src={Logo} />

          <View style={styles.name}>
            <Text style={{ fontWeight: "700", textTransform: "uppercase" }}>
              Hi, {firstNameValue}!
            </Text>
            <Text style={{ fontSize: "13px" }}>Here is your voucher!</Text>
          </View>
          <View style={styles.title}>
            <Text>{data.promo}</Text>
            <Text style={{ color: "red", fontSize: "13px" }}>
              *on Specific items only
            </Text>
          </View>
          <View style={styles.body}>
            <View style={styles.row}>
              <View style={styles.info}>
                <Text>
                  NAME: {firstNameValue} {middleNameValue} {lastNameValue}
                </Text>
                <Text>CONTACT NUMBER: {mobileNumberValue}</Text>
                <Text>EMAIL ADDRESS: {emailValue}</Text>
                <Text>MOTORCYCLE: {modelValue}</Text>
                <Text>
                  BRANCH: {bRegionValue} {bCityValue} {branchValue}
                </Text>
                <Text style={{ lineHeight: "1.3px" }}>PROMO: {data.promo}</Text>
                <Text
                  style={{ color: "red", fontSize: "13px", lineHeight: "2px" }}
                >
                  *on Specific items only
                </Text>
                <Text style={{ fontSize: "18px", fontStyle: "bold" }}>
                  TERMS & CONDITIONS APPLY
                </Text>
              </View>

              <View style={styles.voucher}>
                <Image src={dataUrl} />

                <Text style={{ textAlign: "center", color: "red" }}>
                  {data.voucher}
                </Text>
              </View>
            </View>
            <View style={styles.terms}>
              <Text style={{ fontSize: "16px", color: "#7a7c7d" }}>
                TERMS AND CONDITIONS
              </Text>
            </View>
            <View style={styles.container}>{termsandconditionData}</View>

            <Image
              src={Arawmoto}
              style={{ height: 150, resizeMode: "contain" }}
            />
            <View>{/* <MyDocument /> */}</View>
          </View>
        </Page>
      </Document>
    )
      .toBlob()
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Trancycle Voucher.pdf";
        a.click();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
        alert("Error generating PDF. Please try again.");
      })
      .finally(() => {
        setGeneratingPdf(false);
      });
  };

  const [termsconditionModal, settermsconditionModal] = useState(false);
  const [privacypolicyModal, setprivacypolicyModal] = useState(false);
  // const [agreedToTerms, setAgreedToTerms] = useState(false);
  // const [agreedToPolicy, setAgreedToPolicy] = useState(false);

  // const handleScrollEndPolicy = () => {
  //   setAgreedToPolicy(true); // disable the checkbox
  // };

  // const handleScrollEndTerms = () => {
  //   setAgreedToTerms(true); // disable the checkbox
  // };

  const termandconditionhandleClose = () => {
    document.getElementById("termconditionCheckbox").checked = true;
    settermsconditionModal(false);
  };
  const privacypolicyhandleClose = () => {
    document.getElementById("privacypolicyCheckbox").checked = true;
    setprivacypolicyModal(false);
  };

  return (
    <div className="inner">
      <form onSubmit={submitHandler}>
        <Container fluid>
          <Row className="row-header">
            <Col lg={7} md={7} sm={7} className="column-with-image">
              <img src={Images} alt="Transcycle Logo" />
            </Col>
            <Col lg={5} md={5} sm={5} className="column-with-label">
              <h3>REGISTER</h3>
            </Col>
          </Row>
          <Row>
            <label>Full Name:</label>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${lastNameHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="lname"
                value={lastNameValue}
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
                className="form-control"
                placeholder="Last Name"
              />
              {lastNameHasError && (
                <p className="error-text">Enter Last Name</p>
              )}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${firstNameHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="fname"
                value={firstNameValue}
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
                className="form-control"
                placeholder="First Name"
              />
              {firstNameHasError && (
                <p className="error-text">Enter First Name</p>
              )}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${middleNameHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="mname"
                value={middleNameValue}
                onChange={middleNameChangeHandler}
                onBlur={middleNameBlurHandler}
                className="form-control"
                placeholder="Middle Name"
              />
              {middleNameHasError && (
                <p className="error-text">Enter Middle Name</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              md={12}
              sm={12}
              className={`${mobileNumberHasError ? "invalid" : ""}`}
            >
              <div className="form-group">
                <label htmlFor="phoneNumber">Mobile Number:</label>
                <div className="input-group align-items-center">
                  <div className="input-group-prepend round-0">
                    <span className="input-group-text">+63</span>
                  </div>
                  <input
                    type="tel"
                    maxLength="10"
                    minLength="10"
                    id="mnumber"
                    value={mobileNumberValue}
                    onChange={mobileNumberChangeHandler}
                    onBlur={mobileNumberBlurHandler}
                    className="form-control"
                  />
                </div>
                {mobileNumberHasError && (
                  <p className="error-text">
                    Enter Valid Mobile Number (10 Characters)
                  </p>
                )}
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className={`${emailHasError ? "invalid" : ""}`}
            >
              <div className="form-group">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  id="email"
                  value={emailValue}
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
                  className="form-control"
                />

                {emailHasError && <p className="error-text">Enter E-mail</p>}
              </div>
            </Col>
          </Row>
          <Row>
            <label>Address:</label>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${houseUnitHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="house-unit"
                value={houseUnitValue}
                onChange={houseUnitChangeHandler}
                onBlur={houseUnitBlurHandler}
                className="form-control"
                placeholder="House No./Unit"
              />
              {houseUnitHasError && (
                <p className="error-text">Enter House Number</p>
              )}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${streetHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="street"
                value={streetValue}
                onChange={streetChangeHandler}
                onBlur={streetBlurHandler}
                className="form-control"
                placeholder="Street"
              />
              {streetHasError && (
                <p className="error-text">Enter Street Address</p>
              )}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${barangayHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="barangay"
                value={barangayValue}
                onChange={barangayChangeHandler}
                onBlur={barangayBlurHandler}
                className="form-control"
                placeholder="Barangay"
              />
              {barangayHasError && <p className="error-text">Enter Barangay</p>}
            </Col>
          </Row>
          <Row>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${cityHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="city"
                value={cityValue}
                onChange={cityChangeHandler}
                onBlur={cityBlurHandler}
                className="form-control"
                placeholder="City"
              />
              {cityHasError && <p className="error-text">Enter City</p>}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${regionHasError ? "invalid" : ""}`}
            >
              <input
                type="text"
                id="region"
                value={regionValue}
                onChange={regionChangeHandler}
                onBlur={regionBlurHandler}
                className="form-control"
                placeholder="Region"
              />
              {regionHasError && <p className="error-text">Enter Region</p>}
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className={`${zipCodeHasError ? "invalid" : ""}`}
            >
              <input
                type="tel"
                id="zip-code"
                value={zipCodeValue}
                onChange={zipCodeChangeHandler}
                onBlur={zipCodeBlurHandler}
                className="form-control"
                maxLength={4}
                placeholder="Zip Code"
              />
              {zipCodeHasError && (
                <p className="error-text">
                  Enter Valid Zip Code (4 Characters)
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <Model
                modelValue={modelValue}
                modelChangeHandler={modelChangeHandler}
                modelBlurHandler={modelBlurHandler}
                modelHasError={modelHasError}
              />
            </Col>
          </Row>

          <Row>
            <label>Branch:</label>
            <Col lg={4} md={12} sm={12}>
              <div className="form-group">
                <select
                  className={`form-control ${
                    bRegionHasError ? "invalidSelect" : ""
                  }`}
                  id="region"
                  value={bRegionValue}
                  onChange={bRegionChangeHandler}
                  onBlur={bRegionBlurHandler}
                >
                  {regions.map((branch) => (
                    <option key={branch.value} value={branch.value}>
                      {branch.label}
                    </option>
                  ))}
                </select>
                {bRegionHasError && <p className="error-text">Select Region</p>}
              </div>
            </Col>

            <Col lg={4} md={12} sm={12}>
              <div className="form-group">
                <select
                  className={`form-control ${
                    bCityHasError ? "invalidSelect" : ""
                  }`}
                  id="city"
                  value={bCityValue}
                  onChange={bCityChangeHandler}
                  onBlur={bCityBlurHandler}
                  disabled={!bRegionValue}
                >
                  {bRegionValue ? (
                    cities[bRegionValue]?.map((branch) => (
                      <option key={branch.value} value={branch.value}>
                        {branch.label}
                      </option>
                    ))
                  ) : (
                    <option>Select Branch</option>
                  )}
                </select>
                {bCityHasError && <p className="error-text">Select City</p>}
              </div>
            </Col>
            <Col lg={4} md={12} sm={12}>
              <div className="form-group">
                <select
                  className={`form-control ${
                    branchHasError ? "invalidSelect" : ""
                  }`}
                  id="branch"
                  value={branchValue}
                  onChange={branchChangeHandler}
                  onBlur={branchBlurHandler}
                  disabled={!bRegionValue || !bCityValue}
                >
                  {bCityValue && bRegionValue ? (
                    branches[bCityValue]?.map((mbranch) => (
                      <option key={mbranch.value} value={mbranch.value}>
                        {mbranch.label}
                      </option>
                    ))
                  ) : (
                    <option>Select Branch</option>
                  )}
                </select>
                {branchHasError && <p className="error-text">Select Branch</p>}
              </div>
            </Col>
          </Row>

          <Modal show={showModal} size="lg" onHide={closeModalHandler}>
            <Container>
              <Row className="row-header">
                <Col lg={7} md={7} sm={7} className="column-image">
                  <img src={Images} alt="Example Image" />
                </Col>

                <Col lg={5} md={5} sm={5} className="column-with">
                  <h3>Promotion code </h3>
                </Col>
                {/* <div className="modalClosebtn">
                  <CloseButton aria-label="Hide" onClick={closeModalHandler} />
                </div> */}
              </Row>

              <Row>
                <div className="modal-body">
                  <h3>Thank you for registering!</h3>
                  <h1>Please check your email!</h1>
                </div>
                <Col className="modal-context" lg={6} md={12} sm={12}>
                  <p>
                    <b>Name: </b>
                    {firstNameValue} {middleNameValue} {lastNameValue}
                  </p>{" "}
                  <p>
                    <b>Contact Number: </b>
                    {mobileNumberValue}
                  </p>
                  <p>
                    <b>Email: </b>
                    {emailValue}
                  </p>
                  <p>
                    <b>Model: </b>
                    {modelValue}
                  </p>
                  <p>
                    <b>Branch: </b>
                    {bRegionValue} {bCityValue} {branchValue}
                  </p>
                  <p>
                    <b>Promo: </b>
                    {data.promo}
                  </p>
                  <b style={{ color: "red" }}>(*On specific items only)</b>
                  <br />
                  <a
                    href="#"
                    onClick={() => settermsconditionModal(true)}
                    required
                  >
                    Terms & Conditions
                  </a>
                </Col>
                <Col className="modal-image" lg={6} md={12} sm={12}>
                  <QRCode value={data.qrcode} width={250} />
                  <h3 style={{ color: "#b40e0e" }}>{data.voucher}</h3>
                </Col>
                <div className="modal-note">
                  Please take a screenshot of a voucher and present to the store
                  personel
                </div>
                <Button
                  disabled={generatingPdf}
                  variant="primary"
                  onClick={!generatingPdf ? handleGeneratePdf : null}
                >
                  {generatingPdf ? "Generating PDF..." : "Download"}
                </Button>

                {/* <Button variant="secondary" onClick={closeModalHandler}>
                  Close
                </Button> */}
              </Row>
            </Container>
          </Modal>
          <Modal size="lg" show={termsconditionModal}>
            {/* <TermsConditions onScrollEnd={handleScrollEndTerms} /> */}
            <TermsConditions />

            <Modal.Body>
              <input
                type="checkbox"
                onClick={termandconditionhandleClose}
                // onChange={() => setAgreedToTerms(!agreedToTerms)}
                // disabled={!agreedToTerms}
              />
              {""}
              <label htmlFor="termconditionCheckbox">
                I agree to the Terms and Conditions
              </label>
            </Modal.Body>
          </Modal>
          <Modal size="lg" show={privacypolicyModal}>
            {/* <PrivacyPolicy onScrollEnd={handleScrollEndPolicy} /> */}
            <PrivacyPolicy />
            <Modal.Body>
              <input
                type="checkbox"
                onClick={privacypolicyhandleClose}
                // onChange={() => setAgreedToPolicy(!agreedToPolicy)}
                // disabled={!agreedToPolicy}
              />
              {""}
              <label htmlFor="privacypolicyCheckbox">
                I agree to the Data Privacy and Policy
              </label>
            </Modal.Body>
          </Modal>
          {message?.msg && message?.error && (
            <Alert variant="danger" onClose={() => setMessage("")}>
              <center>{message?.msg}</center>
            </Alert>
          )}

          <Row>
            <Col lg={12} md={12} sm={12} className="terms-and-conditions">
              <p>
                Please read our{" "}
                <a
                  href="#"
                  onClick={() => settermsconditionModal(true)}
                  required
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="#"
                  onClick={() => setprivacypolicyModal(true)}
                  required
                >
                  Data Privacy Policy
                </a>
                <br />
              </p>
              <Row>
                <Col lg={12} md={6} sm={6}>
                  <input
                    type="checkbox"
                    id="termconditionCheckbox"
                    name="termconditionCheckbox"
                    // disabled
                    onClick={() => settermsconditionModal(true)}
                    style={{ height: "15px", width: "15px" }}
                    required
                  />{" "}
                  <label htmlFor="termconditionCheckbox">
                    I agree to the Terms And Conditions
                  </label>
                </Col>
                <Col lg={12} md={6} sm={6}>
                  <input
                    type="checkbox"
                    id="privacypolicyCheckbox"
                    name="privacypolicyCheckbox"
                    onClick={() => setprivacypolicyModal(true)}
                    style={{ height: "15px", width: "15px" }}
                    required
                  />{" "}
                  <label htmlFor="privacypolicyCheckbox">
                    I agree to the Data Privacy Policy
                  </label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <Button
                // variant="primary"
                // size="lg"
                type="submit"
                className="mx-auto d-grid gap-2 btn register-submit-btn"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default Register;
