import axios from "axios";

const customerUrl =
  "https://transcycleapi.retailgate.tech:20304/registration/register";

class Customer {
  addCustomer = (newCustomer) => {
    return axios.post(customerUrl, newCustomer);
  };
}

export default new Customer();
