import { QrScanner } from "@yudiel/react-qr-scanner";
import swal from "sweetalert";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Row, Col, Container } from "react-bootstrap";
import Image from "../assets/transcycle.png";
import { useNavigate } from "react-router-dom";

const Scanner = () => {
  const [qrcontent, setQrcontent] = useState("");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [profileInfo, setProfileInfo] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    model: "",
  });
  const navigate = useNavigate();

  const handleScanResult = (result) => {
    if (!!result) {
      setQrcontent(result?.text);
    }
  };
  const handleScanError = (error) => {
    console.info(error);
  };
  const resetScanner = () => {
    setProfileInfo({
      firstName: "",
      lastName: "",
      middleName: "",
      model: "",
    });
    setSuccess(false);
    setQrcontent("");
    window.location.reload();
  };
  useEffect(() => {
    if (qrcontent === "") {
      /* console.log("CHECKER"); */
      return;
    }
    const handleSubmit = async () => {
      console.log("SUBMITTING");
      const url =
        "https://transcycleapi.retailgate.tech:20304/registration/voucher";

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const requestBody = {
            qrcontent: qrcontent,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          };

          try {
            const myValue = localStorage.getItem("Token");
            const response = await axios.post(url, requestBody, {
              headers: {
                Authorization: "Bearer " + myValue,
              },
            });
            if (response.data.success) {
              setSuccess(true);
              setProfileInfo(response.data);
            } else {
              setSuccess(false);
              setErrorMessage(response.data.errorMessage);
            }
          } catch (error) {
            console.error(error);
            setSuccess(false);
            setErrorMessage("An error occurred while processing your request.");
          }
        },
        (error) => {
          swal("", "Please Allow Location", "warning");
        }
      );
    };

    handleSubmit();
  }, [qrcontent]);
  const handleLogout = () => {
    localStorage.removeItem("Token");
    navigate("/login");
  };
  return (
    <div className="scanner-inner">
      <Container fluid>
        <Row className="row-header">
          <Col lg={7} md={7} sm={7} className="column-with-image">
            <img src={Image} alt="Example Image" />
          </Col>
          <Col lg={5} md={5} sm={5} className="column-with-label">
            <h3>SCANNER</h3>
          </Col>
        </Row>
        {success ? (
          <Row style={{ textAlign: "center" }}>
            <Col lg={12} md={12} sm={12}>
              <h5>Full Name: </h5>
              <p>
                <i>
                  {profileInfo.firstName} {profileInfo.middleName}{" "}
                  {profileInfo.lastName}
                </i>
              </p>

              <h5>Model Name:</h5>
              <p>
                <i>{profileInfo.model}</i>
              </p>

              <button
                className="mx-auto btn scanner-scan-btn"
                onClick={resetScanner}
              >
                Scan Again
              </button>
            </Col>
          </Row>
        ) : (
          <div className="scanner">
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {success && "Success"}
            <QrScanner onResult={handleScanResult} onError={handleScanError} />

            <button
              className="mx-auto btn scanner-logout-btn"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Scanner;
