import React, { useState, useEffect } from "react";
import axios from "axios";

const Model = (props) => {
  const [models, setModels] = useState([]);
  useEffect(() => {
    // const myValue = localStorage.getItem("Token");
    axios
      .get("https://transcycleapi.retailgate.tech:20304/registration/models", {
        // headers: {
        //   Authorization: "Bearer " + myValue,
        // },
      })
      .then((response) => {
        // console.log(response.data);
        const models = response.data.map((model) => ({
          value: model.value,
          label: model.label,
        }));
        setModels(models);

        // console.log(response.data.models);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="form-group">
      <label>Model:</label>
      <select
        className={`form-control ${props.modelHasError ? "invalidSelect" : ""}`}
        value={props.modelValue}
        onChange={props.modelChangeHandler}
        onBlur={props.modelBlurHandler}
      >
        {models.map((model) => (
          <option key={model.value} value={model.value}>
            {model.label}
          </option>
        ))}
      </select>
      {props.modelHasError && <p className="error-text">Select Model</p>}
    </div>
  );
};

export default Model;
