import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Images } from "../assets/index";
import { fetchTermsAndConditions } from "../services/TermsAndConditionAPI";
import "../index.css";

const TermsConditions = () => {
  const [termandcondition, setTermandcondition] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTermsAndConditions();
      setTermandcondition(data);
    };

    fetchData();
  }, []);

  const filteredData = termandcondition.map((item, index) => {
    const regex =
      /((http|https|ftp):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/gi;
    const contentWithLinks = item.content.replace(
      regex,
      (url) => `<a href=${url}>${url}</a>`
    );

    return (
      <div key={index}>
        <p
          dangerouslySetInnerHTML={{
            __html: `${index + 1} ${contentWithLinks}`,
          }}
        ></p>
        {item.subitems && (
          <ul>
            {item.subitems.map((subitem, subindex) => (
              <li key={subindex}>{subitem}</li>
            ))}
          </ul>
        )}
      </div>
    );
  });
  // console.log(filteredData);
  return (
    <div>
      <Container>
        <Row className="row-header">
          <Col lg={7} md={7} sm={7} className="column-image">
            <img src={Images} alt="Transcycle Logo" />
          </Col>
          <Col lg={5} md={5} sm={5} className="column-with">
            <h3>Terms & Conditions </h3>
          </Col>
        </Row>
        <div className="terms-justify">
          <h1>Promo Terms and Conditions</h1>
          <div className="scrollable-container">{filteredData}</div>
        </div>
      </Container>
    </div>
  );
};

export default TermsConditions;
